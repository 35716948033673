.service {
  background: $grey-to-bottom;
}

.service__wrap {
  position: relative;
  max-width: 1140px;
  margin: 33px 0 0 0;
  padding: 52px 0 66px 0;
  z-index: 20;

  @include viewport--md {
    margin: 14px 0 0 0;
    padding: 31px 0 66px 0;
  }

  @include viewport--sm {
    margin: 0;
    padding: 21px 0 52px 0;
  }
}

.service h1 {
  margin: 0 0 42px 0;

  @include viewport--md {
    margin: 0 0 32px 0;
  }

  @include viewport--sm {
    margin: 0 0 24px 0;
  }
}

.service__container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @include viewport--md {
    flex-direction: column;
  }
}

.service__left-block {
  max-width: 701px;
  width: 100%;
  margin: 0 25px 0 0;

  @include viewport--md {
    max-width: 100%;
    margin: 42px 25px 0 0;
  }

  @include viewport--sm {
    margin: 7px 0 0 0;
  }
}

.service__title {
  position: relative;
  display: flex;
  width: 100%;
  height: 340px;
  margin: 0 32px 32px 0;
  padding: 40px;
  background-color: $color-night-rider-2;
  border-radius: 4px;

  @include viewport--md {
    margin: 0;
  }

  @include viewport--sm {
    height: 240px;
  }
}

.service__title img {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  height: 100%;

  @include viewport--md {
    width: 100%;
  }
}

.service__title h2 {
  margin: 0 auto;
  align-self: center;
  text-align: center;
  color: #ffffff;
  z-index: 10;
}

.service__text {
  max-width: 663px;
  margin: 32px 0 0 0;

  @include viewport--md {
    margin: 24px 0 0 0;
    max-width: 100%;
  }

  @include viewport--sm {
    margin: 22px 0 0 0;
  }
}

.service__nav {
  position: relative;
  display: flex;
  width: 100%;
  max-width: 407px;
  background-color: #ffffff;
  box-shadow: 0 100px 100px $color-black-opacity;
  border-radius: 4px;
  z-index: 10;

  @include viewport--md {
    flex-direction: column;
    max-width: 100%;
    order: -1;
  }
}

.service .navigation {
  @include viewport--md {
    padding: 25px 32px 0 32px;
  }

  @include viewport--sm {
    padding: 17px 20px 0 20px;
  }
}
