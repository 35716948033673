.main-news {
  padding-top: 70px;
  padding-bottom: 35px;
  background-color: $color-default-white;

  @include viewport--md {
    padding-top: 48px;
    padding-bottom: 39px;
  }

  @include viewport--sm {
    padding-top: 30px;
    padding-bottom: 10px;
  }
}

.main-news__headline-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include viewport--sm {
    display: block;

    & a {
      display: none;
    }
  }
}

.main-news__title {
  margin-right: 20px;

  @include viewport--sm {
    margin-right: 0;

    text-align: center;
    font-size: 20px;
    line-height: 29px;
  }
}

.main-news__all-news {
  @include viewport--md {
    font-size: 16px;
    line-height: 23px;
  }
}

.main-news__list {
  @include reset-list;

  display: grid;
  flex-wrap: wrap;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 8%;

  margin-top: 72px;

  @include viewport--md {
    margin-top: 50px;
    padding-right: 5px;
    grid-template-columns: 45% 45%;
    column-gap: 10%;
  }

  @include viewport--sm {
    margin-top: 35px;
    display: flex;
    width: 100%;
    padding-bottom: 20px;
    grid-template-columns: unset;
    column-gap: unset;
  }

  &--slider-on {
    flex-wrap: unset;
  }
}

.main-news__item {
  width: 100%;
  margin-bottom: 35px;

  @include viewport--md {
    margin-bottom: 6px;
  }

  @include viewport--sm {
    margin-bottom: 11px;
  }

  & .news-card {
    margin-bottom: 15px;

    @include viewport--sm {
      margin-bottom: 4px;
    }
  }
}

.main-news__pagination {
  display: none;

  @include viewport--sm {
    display: block;
  }

  & span {
    margin-right: 10px;
    background: $color-whisper;
    border: 1px solid $color-matterhorn;
    box-sizing: border-box;

    &.swiper-pagination-bullet-active {
      width: 10px;
      height: 10px;
      background-color: $color-pigment-green;
      border: none;
      vertical-align: middle;
    }
  }
}
