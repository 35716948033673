.clients {
  position: relative;
  padding-top: 85px;
  background: $grey-to-bottom;
  overflow: hidden;
  z-index: 10;

  &::before {
    position: absolute;
    content: "";
    background-image: url("../img/svg/ellipse-2.svg");
    background-repeat: no-repeat, no-repeat;
    background-size: contain;
    width: 100%;
    max-width: 700px;
    height: 114%;
    top: -70%;
    left: 50%;
    transform: translateX(-52%);
    z-index: 0;
  }

  @include viewport--md {
    padding-top: 50px;
    background-size: 65%, 65%;
    background-position: center -574%, 0 0;

    &::before {
      max-width: 500px;
      top: -56%;
      height: 127%;
      transform: translateX(-50%);
    }
  }

  @include viewport--sm {
    padding-top: 32px;
    background-size: 75%, 100%;
    background-position: center -40%, 0 0;

    &::before {
      max-width: 226px;
      top: -30%;
    }
  }
}

.clients__wrapper {
  position: relative;
}

.clients__title {
  text-align: center;

  @include viewport--sm {
    font-size: 20px;
    line-height: 29px;
  }
}

.clients__description {
  text-align: center;
  margin-bottom: 61px;

  @include viewport--md {
    margin-bottom: 50px;
  }

  @include viewport--sm {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 35px;
  }
}

.clients__slider {
  margin: 0 auto;
  width: 847px;

  @include viewport--md {
    padding: 0;
    width: 100%;
  }
}

.clients__slider-wrap {
  @include reset-list;
  padding-bottom: 70px;

  @include viewport--md {
    padding-bottom: 100px;
  }

  @include viewport--sm {
    padding-bottom: 60px;
  }

  & .clients-card {
    margin-bottom: 0;
    margin-right: 0;

    @include viewport--sm {
      width: 100%;
    }
  }
}

.clients__item {
  width: 99%;

  @include viewport--md {
    &--tablet {
      display: none;
    }
  }
}

.clients__item:nth-child(odd) .clients-card {
  @include viewport--md {
    margin-right: 12px;
  }

  @include viewport--sm {
    margin-right: 0;
  }
}

.clients__item:nth-child(even) .clients-card {
  @include viewport--md {
    margin-left: 12px;
  }

  @include viewport--sm {
    margin-left: 0;
  }
}

.clients__prev-slide,
.clients__next-slide {
  top: 50%;
  transform: translateY(50%);
  bottom: 210px;
  left: 6%;

  @media (max-width: 1160px) {
    left: 2%;
  }

  @include viewport--md {
    display: none;
  }
}

.clients__next-slide {
  left: unset;
  right: 6%;

  @media (max-width: 1160px) {
    right: 2%;
  }
}

.clients__pagination {
  display: none;
  width: 100%;
  margin-top: -20px;

  @include viewport--md {
    display: flex;
    justify-content: center;
    min-height: 11px;
    margin-left: -30px;
    bottom: 48px;
  }

  @include viewport--sm {
    margin-left: -21px;
    bottom: 28px;
  }

  & span {
    margin-right: 10px;
    background: $color-whisper;
    border: 1px solid $color-matterhorn;
    box-sizing: border-box;
    outline: none;

    &:last-of-type {
      margin-right: 0;
    }

    &.swiper-pagination-bullet-active {
      width: 10px;
      height: 10px;
      background-color: $color-pigment-green;
      border: none;
      margin-top: -1px;
    }
  }
}
