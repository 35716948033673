.registration-event {
  position: relative;
  width: 100%;
  padding: 35px 32px 37px 35px;
  box-shadow: 0 100px 100px $color-black-opacity;
  background-color: #ffffff;
  border-radius: 4px;
  z-index: 20;

  @include viewport--sm {
    max-width: 100%;
    padding: 35px 32px 30px 35px;
  }
}

.registration-event h2 {
  margin: 0 0 25px 0;

  @include viewport--sm {
    font-size: 16px;
    line-height: 23px;
  }
}

.registration-event__data {
  position: relative;
  margin: 0 0 32px 0;
  padding: 10px 16px 10px 16px;
  font-weight: 500;
  background-color: $color-white-smoke;
  border-radius: 2px;
}

.registration-event svg {
  position: absolute;
  top: 21px;
  right: 20px;
  transform: translateY(-50%);
}

.registration-event__send {
  display: none;
  position: relative;
  margin: -43px 0 0 0;

  &--show {
    display: block;
  }
}

.registration-event__send svg {
  top: 35%;
  left: 38%;
}

.registration-event__button--hidden {
  display: none;
}
