.breadcrumbs {
  @include reset-list;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 650px;
  margin: 0 0 20px 0;
  font-size: 16px;
  line-height: 23px;
  font-weight: 400;

   @include viewport--md {
    max-width: 500px;
  }

  @include viewport--sm {
    max-width: 360px;
    margin: 0 0 9px 0;
    font-size: 12px;
    line-height: 17px;
  }

  &--news {
    max-width: 663px;
    margin: 0 auto 24px auto;
    padding-left: 18px;

    @media (max-width: 1400px) {
      width: 472px;
    }

    @include viewport--sm {
      margin: 0 auto 24px auto;
      width: 490px;
    }
  }

  &--news-mod {
    max-width: 555px;
    margin: 0 0 24px 0;
    padding: 0;

    @include viewport--sm {
      margin-bottom: 12px;
    }
  }
}

.breadcrumbs__item {
  position: relative;
  margin: 0 18px 0 0;

  &:not(:last-child)::before {
    position: absolute;
    content: "/";
    right: -13px;
  }

  @include viewport--sm {
    margin: 0 13px 0 0;

    &:not(:last-child)::before {
      right: -10px;
    }
  }
}

.breadcrumbs__item:not(:last-child) a {
  &:hover {
    opacity: 0.6;
    transition: 0.4s;
  }
}

.breadcrumbs__item a {
  color: $color-night-rider;
  text-decoration: underline;
}

.breadcrumbs__item:last-of-type a {
  color: $color-nobel;
  text-decoration: none;
  pointer-events: none;
  cursor: text;
}
