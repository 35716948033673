.button {
  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 261px;
  max-width: 100%;
  min-height: 62px;
  padding: 18px;

  border: none;
  border-radius: 31px;

  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: $color-default-white;
  -webkit-transition: background-color 0.4s ease-out;
  -moz-transition: background-color 0.4s ease-out;
  -o-transition: background-color 0.4s ease-out;
  transition: background-color 0.4s ease-out;

  background-color: $color-pigment-green;

  cursor: pointer;

  @include viewport--md {
    font-size: 16px;
    line-height: 23px;
  }

  @include viewport--md {
    min-width: 220px;
    min-height: 42px;
    padding: 9px 15px;

    font-size: 14px;
    line-height: 20px;
  }

  &--more {
    @include viewport--md {
      min-width: 261px;
      min-height: 62px;
      padding: 18px;

      font-size: 18px;
      line-height: 26px;
    }

    @include viewport--sm {
      min-width: 261px;
      min-height: 42px;
      padding: 10px 15px;

      font-size: 14px;
      line-height: 20px;
    }
  }

  &--narrow {
    min-width: 170px;
    min-height: 42px;
    padding: 11px 20px;

    font-size: 14px;
    line-height: 20px;

    @include viewport--sm {
      min-height: 32px;
      padding: 6px 15px;

      font-size: 12px;
      line-height: 17px;
    }
  }

  &--registration {
    width: 100%;
    min-width: auto;
    min-height: 42px;
    padding: 8px;
    outline: none;

    font-size: 14px;
    line-height: 20px;

    @include viewport--sm {
      padding: 6px;

      font-size: 12px;
      line-height: 17px;
    }
  }

  &--round {
    min-width: unset;
    min-height: unset;
    max-width: unset;
    padding: 0;
    width: 52px;
    height: 52px;

    border-radius: 50%;

    &::before,
    &::after {
      content: "";

      position: absolute;
      top: 50%;

      width: 10px;
      height: 2px;
      border-radius: 20%;
      background-color: $color-default-white;
    }
  }

  &--round-prev {
    &::before {
      transform: translateY(-3px) rotate(-45deg);
    }

    &::after {
      transform: translateY(3px) rotate(45deg);
    }
  }

  &--round-next {
    &::before {
      transform: translateY(3px) rotate(-45deg);
    }

    &::after {
      transform: translateY(-3px) rotate(45deg);
    }
  }

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: $color-pigment-green-1;
    -webkit-transition: background-color 0.4s ease-out;
    -moz-transition: background-color 0.4s ease-out;
    -o-transition: background-color 0.4s ease-out;
    transition: background-color 0.4s ease-out;
  }

  &:active {
    background-color: $color-salem;
    outline: none;
  }

  &--disabled,
  &:disabled {
    background-color: $color-silver;
  }
}
