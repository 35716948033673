.headline {
  background: url("../img/svg/headline-ellipse.svg") no-repeat 0 100%;

  @include viewport--md {
    background-image: url("../img/svg/headline-ellipse-gray-mobile.svg");
  }

  @include viewport--sm {
    background-size: 29%;
  }
}

.headline__wrap {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-bottom: 53px;
  margin-top: -15px;
  z-index: 10;

  @include viewport--sm {
    display: block;
    margin-top: 0;
    padding-bottom: 63px;
  }
}

.headline__wrapper {
  margin-top: 70px;
  margin-right: 20px;

  @include viewport--md {
    margin-top: 50px;
    margin-right: 20px;
  }

  @include viewport--sm {
    margin: 15px auto 43px;
  }
}

.headline__title {
  @include viewport--sm {
    text-align: center;
  }
}

.headline__description {
  margin-top: 34px;
  max-width: 395px;

  font-weight: normal;

  & br {
    display: none;
  }

  @include viewport--sm {
    text-align: center;
    margin: 10px auto 20px;
    padding: 0 10px;

    font-size: 14px;
    line-height: 20px;

    & br {
      display: inline;
    }
  }
}

.headline__more-link {
  margin-top: 60px;
  max-width: 66%;
  min-width: 206px;


  @include viewport--md {
    min-width: 219px;
    font-size: 16px;
    line-height: 23px;
    margin-top: 40px;
  }

  @include viewport--sm {
    margin: 0 auto;
    font-size: 14px;
    line-height: 20px;
  }
}

.headline__slider {
  max-width: 698px;
  margin-top: -20px;

  @include viewport--md {
    max-width: 400px;
    margin-top: 0;
  }

  @include viewport--sm {
    max-width: 400px;
    margin: 0 auto;
  }
}

.headline h2 {
  @include viewport--sm {
    max-width: 255px;
    margin: 0 auto;
  }
}
