.news-card {
  display: none;
  margin-right: 80px;
  margin-bottom: 47px;
  width: calc(33% - 80px);

  &--components {
    margin-right: 0;
    margin-bottom: 0;
    width: 100%;
  }

  &--filtered {
    display: block;
  }

  @media (max-width: 1150px) {
    margin-right: 68px;
    width: calc(33% - 68px);

    &--components {
      margin-right: 0;
      margin-bottom: 0;
      width: 100%;
    }
  }

  @include viewport--md {
    margin-right: 68px;
    margin-bottom: 40px;
    width: calc(50% - 68px);

    &--components {
      margin-right: 0;
      margin-bottom: 0;
      width: 100%;
    }
  }

  @include viewport--md-only {
    max-width: 435px;
  }

  @include viewport--sm {
    margin-right: 0;
    margin-bottom: 21px;
    width: 100%;
  }
}

.news-card--filtered.news-card--hidden {
  display: none;
}

.news-card strong {
  height: 58px;
  margin: 0 0 12px;
  color: $color-night-rider;
  font-weight: 600;
  font-size: 20px;
  line-height: 29px;
  text-decoration-line: underline;
  -webkit-transition: color 0.4s ease-out;
  -moz-transition: color 0.4s ease-out;
  -o-transition: color 0.4s ease-out;
  transition: color 0.4s ease-out;
  // стили для ограничения количества строк и многоточия в месте обрезанного текста
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;

  @include viewport--md {
    height: 52px;
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 7px;
  }

  @include viewport--sm {
    height: unset;
    margin-bottom: 9px;
    font-size: 16px;
    line-height: 23px;
  }
}

.news-card--big-on-tablet strong {
  @include viewport--md-only {
    font-size: 20px;
    line-height: 29px;
    margin-bottom: 11px;
  }
}

.news-card p {
  height: 69px;
  margin: 0 0 12px;
  color: $color-matterhorn;
  font-size: 16px;
  line-height: 23px;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;


  @include viewport--md {
    margin-bottom: 10px;
  }

  @include viewport--sm {
    height: unset;
    margin-bottom: 4px;
    font-size: 12px;
    line-height: 17px;
  }
}

.news-card time {
  color: $color-silver;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: -0.24px;

  @include viewport--sm {
    font-size: 10px;
    line-height: 14px;
  }
}

.news-card__link:hover strong {
  color: $color-pigment-green;
  -webkit-transition: color 0.4s ease-out;
  -moz-transition: color 0.4s ease-out;
  -o-transition: color 0.4s ease-out;
  transition: color 0.4s ease-out;
}

.news-card__link:active strong {
  color: $color-silver;
}
