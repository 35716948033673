input,
textarea {
  width: 100%;
  padding: 11px 16px;
  font-family: $font-family--jost;
  font-size: 16px;
  color: $color-night-rider;
  background-color: $color-default-white;
  border: 1px solid $color-whisper;
  border-radius: 2px;
  cursor: pointer;

  &::placeholder {
    color: $color-nobel;
    font-size: 16px;
    line-height: 23px;
  }

  &:focus,
  &:hover {
    color: $color-night-rider;
    border-color: $color-pigment-green;
    outline: none;
  }

  &:disabled {
    background: $color-white-smoke;
    border-color: transparent;
    border-radius: 2px;
  }
}

.input {
  &--valid {
    border-color: $color-pigment-green;
  }

  &--invalid,
  &--invalid:focus {
    border-color: $color-dark-red;
    color: $color-dark-red;
    background-image: url("../img/svg/icon-alert.svg");
    background-repeat: no-repeat;
    background-size: 16px 16px;
    background-position: right 17px top 12px;

    &::placeholder {
      color: $color-dark-red;
    }
  }
}

label {
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;

  @include viewport--sm {
    font-size: 12px;
    line-height: 17px;
  }
}

input::placeholder,
textarea::placeholder {
  @include viewport--sm {
    font-size: 14px;
  }
}
