@mixin retina {
  @media (min-resolution: $retina-dpi), (min-resolution: $retina-dppx) {
    @content;
  }
}

// Desktop first

// до 1920px
@mixin viewport--xl {
  @media (max-width: $viewport--xxl - 1) {
    @content;
  }
}

// до 1440px
@mixin viewport--lg {
  @media (max-width: $viewport--xl - 1) {
    @content;
  }
}

// до 1024px
@mixin viewport--md {
  @media (max-width: $viewport--lg - 1) {
    @content;
  }
}

// 768px-1023px
@mixin viewport--md-only {
  @media (max-width: $viewport--lg - 1) and (min-width: $viewport--md) {
    @content;
  }
}

// до 768px
@mixin viewport--sm {
  @media (max-width: $viewport--md - 1) {
    @content;
  }
}

// Reset list indents

@mixin reset-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

// Mobile first

/* @mixin viewport--md {
  @media (min-width: $viewport--md) {
    @content;
  }
}

@mixin viewport--lg {
  @media (min-width: $viewport--lg) {
    @content;
  }
}

@mixin viewport--xl {
  @media (min-width: $viewport--xl) {
    @content;
  }
}

@mixin viewport--xxl {
  @media (min-width: $viewport--xxl) {
    @content;
  }
} */
