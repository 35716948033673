.form__input {
  margin-bottom: 14px;
}

.form__checkbox {
  display: flex;
  padding-top: 5px;
  margin: 0 0 32px 0;
}

.form__checkbox input {
  width: 24px;
  height: 24px;
}

.form__checkbox label {
  position: relative;
  font-size: 16px;
  line-height: 23px;
  color: $color-nobel;
  padding: 0 0 0 40px;

 &::before {
  position: absolute;
  content: "";
  width: 24px;
  height: 24px;
  left: 0;
  border-radius: 2px;
  border: 1px solid $color-whisper;
  cursor: pointer;
  }
}

.form__button {
  width: 170px;
}

.form__checkbox input:checked + label::after {
  position: absolute;
  content: "";
  width: 22px;
  height: 22px;
  left: 1px;
  top: 6px;
  background-image: url("../img/svg/arrow.svg");
  background-size: contain;
  background-repeat: no-repeat;
  transform: rotate(90deg);
}

.form__block {
  &--hidden {
    display: none;
  }
}

.form__data {
  position: relative;
}
