.table {
  width: 100%;
  font-size: 16px;
  line-height: 23px;
  color: $color-night-rider;

  border: 1px solid $color-whisper-2;
  border-collapse: collapse;

  & th {
    background-color: $color-white-smoke-2;
    text-align: left;
  }

  & th,
  td {
    padding: 20px 30px;

    border: 1px solid $color-whisper-2;
    border-collapse: collapse;
  }
}
