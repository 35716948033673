.product {
  background: $grey-to-bottom;
}

.product__container {
  position: relative;
  max-width: 1140px;
  margin: 33px 0 0 0;
  padding: 52px 0 66px 0;
  z-index: 20;

  @include viewport--md {
    margin: 14px 0 0 0;
    padding: 32px 0 66px 0;
  }

  @include viewport--sm {
    margin: 0;
    padding: 21px 0 52px 0;
  }
}

.product h1 {
  margin: 20px 0 42px 0;

  @include viewport--sm {
    margin: 9px 0 31px 0;
  }
}

.product__wrap {
  display: flex;
  justify-content: space-between;

  @include viewport--md {
    flex-direction: column;
  }
}

.product__nav {
  width: 100%;
  max-width: 407px;
  min-width: 300px;
  margin: 0 25px 0 0;

  @include viewport--md {
    max-width: 100%;
    margin: 0;
  }
}

.product__image {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 407px;
  height: 160px;
  margin: 0 0 20px 0;
  background-color: $color-white-smoke;

  @include viewport--md {
    max-width: 100%;
    margin: 0 0 31px 0;
  }

  @include viewport--sm {
    height: 120px;
    margin: 0 0 28px 0;
  }
}

.product__image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.product__block {
  padding: 0;
  box-shadow: 0 100px 100px $color-black-opacity;

  @include viewport--md {
    padding: 0;
  }

  @include viewport--sm {
    margin: 0 0 9px 0;
  }
}

.product__block br {
  display: none;

  @include viewport--sm {
    display: block;
  }
}

.product__block-text {
  max-width: 662px;
  margin: 0 8px 0 0;

  @include viewport--md {
    max-width: 100%;
    margin: 41px 0 0 0;
  }

  @include viewport--sm {
    margin: 6px 0 0 0;
  }
}

.product__block .navigation__item {
  margin-right: 18px;
}
