.container {
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
  padding-left: 50px;
  padding-right: 50px;

  @include viewport--md {
    padding: 0 30px;
  }

  @include viewport--sm {
    padding: 0 20px;
  }

  &--no-padding {
    @include viewport--sm {
      padding: 0;
    }
  }
}
