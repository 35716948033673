.about {
  background: $grey-to-bottom;
}

.about__block {
  position: relative;
  margin: 33px 0 0 0;
  padding: 51px 0 0 0;
  color: $color-night-rider;
  z-index: 20;

  @include viewport--md {
    margin: 11px 0 0 0;
    padding: 32px 0 0 0;
  }

  @include viewport--sm {
    margin: 0;
    padding: 21px 0 0 0;
  }
}

.about__nav {
  margin: 0 0 31px 0;

  @include viewport--md {
    margin: 0 0 33px 0;
  }

  @include viewport--sm {
    margin: 0 0 24px 0;
  }
}

.about h1 {
  margin: 21px 0 42px 0;

  @include viewport--md {
    margin: 20px 0 42px 0;
  }

  @include viewport--sm {
    margin: 9px 0 18px 0;
  }
}

.about__block-nav {
  margin: 0 0 31px 0;

  @include viewport--sm {
    margin: 0 0 25px 0;
  }
}

.about__text {
  margin: 0 0 45px 0;

  @include viewport--sm {
    margin-bottom: 20px;
  }
}

.about__text.content h2 {
  @include viewport--sm {
    font-size: 15px;
  }
}

.about__text.content p {
  margin: 0 0 31px 0;

  @include viewport--md {
    margin: 0 0 25px 0;
  }

  @include viewport--sm {
    margin: 0 0 17px 0;
  }
}

.about__text.content li {
  margin-bottom: 10px;
  width: 535px;

  @media (max-width: 1100px) {
    width: 500px;
  }

  @include viewport--md {
    margin-bottom: 12px;
    width: 320px;
  }

  @include viewport--sm {
    margin-bottom: 6px;
  }
}
.about__text.content ul {
  display: inline-block;
  max-width: 900px;
  column-gap: 0;

  @include viewport--md {
    column-gap: 59px;
    padding-bottom: 8px;
  }
}

.about__text.content li {
  display: list-item;
  margin-right: 25px;
  margin-bottom: 13px;
  width: 431px;

  @include viewport--md {
    margin-bottom: 12px;
    width: 100%;
  }

  @include viewport--sm {
    margin-bottom: 6px;
  }
}

.about h2 {
  margin: 0 0 14px 0;

  @include viewport--md {
    margin: 0 0 16px 0;
  }

  @include viewport--sm {
    margin: 0 0 2px 0;
  }
}

.about h3 {
  margin: 0 0 33px 0;

  @include viewport--sm {
    display: none;
  }
}

.about__block-licenses {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -106px 32px 0;

  @include viewport--md {
    margin: 0 -59px 52px 0;
  }

  @include viewport--sm {
    margin: 0 -20px 44px 0;
    padding: 10px 0 0 0;
  }
}

.about__license {
  display: flex;
  flex-direction: column;
  width: 335px;
  margin: 0 106px 50px 0;

  @media (max-width: 1024px) {
    margin-right: 30px;
  }


  @include viewport--md {
    margin: 0 59px 30px 0;
    width: 324px;
  }

  @include viewport--sm {
    width: 130px;
    margin: 0 20px 15px 0;
  }
}

.about__license:last-of-type {
  margin-right: 0;
}

.about__license img {
  padding: 20px 30px 20px 30px;
  width: 335px;
  height: 420px;

  @include viewport--md {
    padding: 20px 25px 20px 25px;
  }

  @include viewport--sm {
    margin: 0 auto 0 auto;
    padding: 10px 10px 10px 10px;
    width: 130px;
    height: 172px;
  }
}

.about__license-block {
  background-color: #ffffff;
  margin: 0 auto 0 auto;
  box-shadow: 0 100px 100px 0 $color-black-opacity;
  border-radius: 4px;

  @include viewport--sm {
    width: 130px;
    height: auto;
  }
}

.about__license p {
  width: 100%;
  max-width: 300px;
  margin: 0;
  padding: 23px 0 0 0;
  font-size: 16px;
  line-height: 23px;
  font-weight: 400;
  background-color: transparent;

  @include viewport--md {
    padding: 23px 0 0 0;
  }

  @include viewport--sm {
    padding: 17px 0 0 0;
    font-size: 12px;
    line-height: 17px;
    width: 126px;
  }
}

.about__container {
  display: none;

  &--active {
    display: block;
  }

  &--show {
    display: block;
  }
}
