.contacts {
  margin-top: 34px;
  padding-top: 51px;
  padding-bottom: 75px;
  background-image: $grey-to-bottom;

  @include viewport--md {
    margin-top: 13px;
    padding-top: 33px;
    padding-bottom: 74px;
  }

  @include viewport--sm {
    margin-top: 1px;
    padding-top: 21px;
    padding-bottom: 54px;
  }
}

.contacts__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 41px;
  position: relative;
  z-index: 3;

  @include viewport--md {
    flex-wrap: wrap;
    margin-top: 19px;
  }

  @include viewport--sm {
    margin-top: 23px;
  }
}

.contacts__wrapper .title {
  margin-bottom: 27px;
  color: $color-night-rider;

  @include viewport--md {
    margin-bottom: 25px;
  }

  @include viewport--sm {
    margin-bottom: 19px;
  }
}

.contacts__form {
  padding: 33px 33px 34px;
  width: 35.7%;
  background-color: $color-default-white;
  border-radius: 4px;
  box-shadow: 0 100px 100px 0 $color-black-opacity;

  @include viewport--md {
    margin-bottom: 20px;
    padding-bottom: 32px;
    width: 100%;
  }

  @include viewport--sm {
    padding: 18px 18px 21px;
    margin-bottom: 25px;
  }
}

.contacts__form-wrapper {
  margin-bottom: 10px;

  @include viewport--md {
    margin-bottom: 15px;
  }

  @include viewport--sm {
    margin-bottom: 18px;
  }

  @include viewport--md-only {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
  }
}

.contacts__form-inner {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 17px;

  @include viewport--sm {
    margin-bottom: 11px;
  }
}

.contacts__form-wrapper > .contacts__form-inner,
.contacts__form-wrapper > .contacts__inputs-wrapper {
  @include viewport--md-only {
    width: 47.5%;
  }
}

.contacts__form-inner span {
  display: none;
  max-width: 60%;
  overflow-wrap: break-word;
  margin-left: auto;
  font-size: 14px;
  line-height: 20px;
  color: $color-dark-red;
}

.contacts__form-inner .error--show {
  display: block;
}

.contacts__form label {
  margin-right: 10px;
  margin-bottom: 9px;

  @include viewport--sm {
    margin-bottom: 12px;
  }
}

.contacts__form textarea {
  @include viewport--md-only {
    height: 132px;
  }
}

.contacts__message {
  display: none;
}

.contacts__map {
  width: 61.67%;

  @include viewport--md {
    width: 100%;
  }
}

.contacts__map iframe {
  width: 100%;
  border: none;
  border-radius: 2px;
}

/*стили для отправки формы*/
.contacts__form--send-ok form,
.contacts__form--send-error form {
  display: none;
}

.contacts__form--send-ok .contacts__message--ok {
  display: block;
}

.contacts__form--send-error .contacts__message--error {
  display: block;
}
