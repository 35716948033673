.news {
  margin-top: 34px;
  padding: 52px 0 83px;
  background-image: $grey-to-bottom;

  @include viewport--md {
    margin-top: 13px;
    padding-top: 33px;
    padding-bottom: 82px;
  }

  @include viewport--sm {
    margin-top: 1px;
    padding-top: 21px;
    padding-bottom: 62px;
  }
}

.news h1 {
  margin: 21px 0 42px 0;

  @include viewport--md {
    margin: 20px 0 42px 0;
  }

  @include viewport--sm {
    margin: 9px 0 18px 0;
  }
}

.news__filter-buttons {
  position: relative;
  z-index: 3;
  margin-top: 41px;

  @include viewport--sm {
    margin-top: 17px;
  }
}

.news__content {
  padding-top: 32px;

  @include viewport--md {
    padding-top: 23px;
  }

  @include viewport--sm {
    padding-top: 25px;
  }
}

.news__filter-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 80px);
  margin-bottom: -1px;

  @include viewport--md {
    width: calc(100% + 68px);
    margin-bottom: 19px;
  }

  @include viewport--sm {
    margin-bottom: 18px;
    width: 100%;
  }
}

.news__filter .show-more {
  margin: 0 auto;
}

.news--no-js .buttons-group__btn:not(.buttons-group__btn--active) {
  opacity: 0.3;
  pointer-events: none;
  cursor: text;
}

.news__filter {
  display: none;
}

.news__filter--active {
  display: block;
}

// Класс убирает отступ, если кнопка показать ещё спрятана
.news__mod {
  padding-bottom: 0;
}
