.content {
  font-family: $font-family--jost;
  font-style: normal;
  font-size: 18px;
  line-height: 26px;
  color: $color-night-rider;

  @include viewport--md {
    font-size: 16px;
    line-height: 23px;
  }

  @include viewport--sm {
    font-size: 15px;
    line-height: 22px;
  }

  & a {
    position: relative;
    color: $color-pigment-green;
    transition: 0.4s;
    border-bottom: 1px solid $color-pigment-green;
    line-height: 0.9em;

    &:hover {
      color: $color-salem;
      border-color: $color-salem;
      }

      &:active {
        color: $color-nobel;
        border-color: $color-nobel;
    }
  }

  & ul {
    @include reset-list;
    display: block;
    column-count: 1;
    margin: 0 11px 0 0;
    padding: 8px 0 20px 0;
    column-gap: 28px;

    @include viewport--md {
      margin: 0;
      column-gap: 66px;
    }

    @include viewport--sm {
      margin: 0;
      padding: 14px 0 16px 0;
    }

    & li {
      position: relative;
      font-weight: 400;
      margin: 0 0 12px 0;
      padding: 0 0 0 20px;
      font-size: 16px;
      line-height: 23px;
      -webkit-column-break-inside:avoid;
      -moz-column-break-inside:avoid;
      -o-column-break-inside:avoid;
      -ms-column-break-inside:avoid;
      break-inside: avoid;

      &::before {
        position: absolute;
        content: "";
        width: 8px;
        height: 8px;
        left: 0;
        top: 7px;
        border-radius: 50%;
        background-color: $color-pigment-green;
      }

      @include viewport--sm {
        display: block;
        font-size: 13px;
        line-height: 19px;
        margin: 0 0 8px;
        padding: 0 0 0 16px;

        &::before {
          width: 6px;
          height: 6px;
          top: 6px;
        }
      }
    }
  }

  & ol {
    @include reset-list;
    counter-reset: num;

    & li {
      position: relative;
      font-weight: 400;
      margin: 0 0 12px 0;
      padding: 0;
      font-size: 16px;
      line-height: 23px;

      &::before {
        content: counter(num) '. ';
        counter-increment: num;
        font-weight: 600;
        font-size: 16px;
        line-height: 23px;
        color: $color-pigment-green;
      }

      @include viewport--sm {
        font-size: 13px;
        line-height: 19px;

        &::before {
          font-size: 13px;
          line-height: 19px;
        }
      }
    }
  }

  & p {
    font-size: 18px;
    line-height: 26px;
    margin: 0 0 16px 0;

    @include viewport--sm {
      font-size: 15px;
      line-height: 22px;
      margin: 0 0 10px 0;
    }

    & span {
      font-weight: 500;
    }
  }

  & b {
    font-size: 18px;
    line-height: 26px;
    font-weight: 500;

    @include viewport--sm {
      font-size: 15px;
      line-height: 22px;
    }
  }
}

.content--news {
  & ul {
    padding-top: 14px;
    column-gap: 59px;
    margin-right: 16px;
    padding-bottom: 20px;
  }
}

.content--two-column {
  & ul {
    column-count: 2;

    @include viewport--sm {
      column-count: 1;
    }
  }
}
