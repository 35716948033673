/* stylelint-disable value-no-vendor-prefix */
/* stylelint-disable property-no-vendor-prefix */
.header {
  position: relative;
  padding-top: 50px;
  margin-bottom: 15px;

  font-family: $font-family--jost;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;

  &--news {
    margin-bottom: 57px;
  }

  @include viewport--md {
    padding-top: 30px;
  }

  @include viewport--sm {
    padding-top: 18px;
  }
}

.header__ellipse {
  position: absolute;
  width: 50%;
  height: 338%;
  top: 0;
  right: -15px;
  z-index: 2;

  background-color: transparent;
  background-image: url("../img/svg/header-ellipse.svg");
  background-size: 505px;
  background-position: 96% 0;
  background-repeat: no-repeat;

  @include viewport--md {
    background-image: url("../img/svg/headline-ellipse-mobile.svg");
    background-size: 105%;
    background-position: 100% 0;
    background-repeat: no-repeat;
  }

  @include viewport--sm {
    background-size: 90%;
    background-position: 62% 0;
  }

  @media (max-width: 500px) {
    background-size: 112%;
  }
}


.header__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__menu-btn {
  display: none;
  justify-content: center;
  align-items: center;

  width: 52px;
  height: 52px;
  z-index: 3;
  position: relative;

  border: none;
  border-radius: 50%;
  background-color: $color-default-white;
  outline: none;
  cursor: pointer;

  @include viewport--md {
    padding: 0;
    display: flex;
    z-index: 26;
    margin-bottom: 19px;
  }

  @include viewport--sm {
    margin-bottom: 0;
    width: 32px;
    height: 32px;
  }

  & .header__menu-icon {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);

    @include viewport--sm {
      & svg {
        width: 15px;
      }
    }

    &--close {
      display: none;
    }
  }

  &--open {
    background-color: $color-pigment-green;

    & .header__menu-icon {
      display: none;

      &--close {
        display: block;
      }
    }
  }
}

.header__logo {
  flex-grow: 2.5;

  @include viewport--sm {
    & img {
      width: 102px;
    }
  }

  &[href="main.html"] {
    cursor: pointer;
  }
}

.header__overlay {
  @include viewport--md {
    display: none;
    visibility: hidden;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    opacity: 0;
    transition: all 0.2s ease-in-out;
    overflow: hidden;

    background-color: $color-default-white;

    &--show {
      display: block;
      visibility: visible;
      opacity: 0.6;
      z-index: 25;
    }
  }
}

.header__overlay--hide {
  @include viewport--md {
    display: block;
    opacity: 0;
    visibility: hidden;
    transition: all 0.7s ease-in-out;
  }
}

.header__nav {
  flex-grow: 3;
  z-index: 3;
  max-width: 750px;

  margin-top: 12px;
  margin-left: 20px;
  margin-bottom: 35px;
  padding: 15px 20px 15px 25px;

  box-shadow: 0 30px 60px rgba(0, 0, 0, 0.1);
  border-radius: 26px;
  background-color: $color-default-white;

  @include viewport--md {
    z-index: 25;
    position: fixed;
    top: -12px;
    right: 0;

    height: 100vh;
    width: 74.5vw;
    max-width: unset;
    overflow-y: scroll;

    border-radius: unset;

    opacity: 0;
    transform: translate(100%, 0);
  }

  @include viewport--sm {
    padding: 10px 18px;
    width: 76vw;
  }
}

.header__nav--open {
  @include viewport--md {
    transform: translate(0, 0) scale(1, 1);
    transition: all 0.5s ease-in-out;
    -webkit-font-smoothing: antialiased;
    opacity: 1;
    overflow-x: hidden;
  }
}

.header__nav--hide {
  @include viewport--md {
    transform: translate(100%, 0);
    opacity: 0;
    transition: all 0.5s ease-in-out;
  }
}

.header__nav-title {
  display: none;
  padding-left: 30px;
  padding-top: 5px;

  @include viewport--md {
    display: block;

    font-weight: 500;
    font-size: 32px;
    line-height: 46px;
    opacity: 0.3;
  }

  @include viewport--sm {
    font-size: 20px;
    line-height: 29px;

    margin: 17px 0 44px 0;
    padding-left: 15px;
    padding-top: 0;
  }
}

.header__nav-list {
  @include reset-list;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  padding-top: 0;

  @include viewport--md {
    flex-direction: column;
    align-items: flex-start;
    z-index: 3;
  }
}

.header__nav-item {
  position: relative;
  outline: none;
  cursor: pointer;

  @include viewport--md {
    margin-bottom: 3px;
    width: 100%;
  }

  @include viewport--sm {
    margin-bottom: 22px;

    & a {
      padding-left: 15px;
    }
  }
}

.header__nav-item--company:hover .header__nav-link,
.header__nav-item--company:focus .header__nav-link {
  color: $color-pigment-green-1;
  -webkit-transition: color 0.4s ease-out;
  -moz-transition: color 0.4s ease-out;
  -o-transition: color 0.4s ease-out;
  transition: color 0.4s ease-out;
}

.header__nav-item--company:active .header__nav-link {
  color: $color-silver;
}

.header__nav-link {
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  padding: 0 9px;

   @include viewport--md {
    display: block;
    font-size: 32px;
    line-height: 46px;
    padding: 30px;
  }

  @include viewport--sm {
    font-size: 20px;
    line-height: 29px;
    padding: 5px 20px;
  }

  &--active {
    cursor: default;

    &:hover {
      color: $color-night-rider;
    }
  }
}

.header__logo-small {
  display: none;
}

.header__block {
  position: relative;
  z-index: 40;
}

// Класс шапки липкой
.header--sticky {
  @media (min-width: 1024px) {
    padding-top: 152px;
  }
}

.header--sticky .header__block{
  @media (min-width: 1024px) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #ffffff;
    box-shadow: 0 1px 3px $color-black-opacity;
    z-index: 100;
    animation-name: show-header;
    animation-duration: 1s;
  }
}

.header--sticky .header__nav {
  @media (min-width: 1024px) {
    margin-bottom: 10px;
  }
}

@keyframes show-header {
  0% {
    opacity: 0;
    top: -70px;
  }
  100% {
    opacity: 1;
    top: 0;
  }
}

.header--sticky .header__logo-small {
  @media (min-width: 1024px) {
    display: block;
    margin: 13px 0 0 0;
  }
}

.header--sticky .header__logo-big {
  @media (min-width: 1024px) {
    display: none;
  }
}

// Подменю
.header__submenu-wrap {
  position: absolute;
  width: 206px;
  display: none;
  padding: 21px 0 0 0;
  -webkit-transition: opacity 0.4s ease-out;
  -moz-transition: opacity 0.4s ease-out;
  -o-transition: opacity 0.4s ease-out;
  transition: opacity 0.4s ease-out;
  top: 24px;
  left: -11px;

  @include viewport--md {
    position: relative;
    padding: 0;
    width: 100%;
    left: 35px;
    top: 0;
  }

  @include viewport--sm {
    left: 15px;
  }
}

.header__nav-arrow-wrap {
  position: absolute;
  top: 10px;
  right: -6px;
  z-index: 20;

  @media (max-width: 1023px) {
    top: 35px;
    right: 41px;
  }

  @include viewport--sm {
    top: -3px;
    right: 6px;
  }
}

.header__nav-arrow {
  width: 6px;
  height: 12px;
  background-image: url('../img/svg/arrow.svg');
  background-repeat: no-repeat;
  background-size: contain;

  @media (max-width: 1023px) {
    width: 13px;
    height: 26px;
    margin: 10px 10px 5px 10px;
  }

  @include viewport--sm {
    width: 13px;
    height: 26px;
  }
}

.header__submenu {
  @include reset-list;
  position: relative;
  width: 100%;
  padding: 15px 15px 15px 15px;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  z-index: 20;

  &::before {
    position: absolute;
    content: '';
    width: 32px;
    height: 16px;
    left: 24px;
    top: -52px;
    border: 40px solid transparent;
    border-bottom: 30px solid #ffffff;
    pointer-events: none;
  }

  @include viewport--md {
    box-shadow: none;
    background-color: transparent;
    padding: 25px 15px 25px 15px;

    &::before {
      display: none;
    }
  }

  @include viewport--sm {
    padding: 20px 15px 0 15px;
  }
}

.header__submenu-item {
  margin-bottom: 20px;

  @include viewport--md {
   margin-bottom: 22px;

   &:last-of-type {
     margin-bottom: 0;
    }
  }

  @include viewport--md {
    margin-bottom: 30px;
  }
}

.header__submenu-item:last-child {
  margin-bottom: 0;
}

.header__submenu-link {
  font-size: 16px;
  line-height: 24px;
  position: relative;
  display: block;
  padding: 0;

  @include viewport--md {
    font-size: 32px;
    line-height: 38px;
   }

  @include viewport--sm {
    padding-left: 0;
    font-size: 20px;
    line-height: 24px;
   }
}

.header__submenu-item .header__submenu-link {
  @include viewport--sm {
    padding-left: 0;
  }
}

.header__nav-item--company:hover .header__nav-arrow {
  @media (min-width: 1126px) {
    transform: rotate(90deg);
  }
}

.header__nav-item--company:hover .header__submenu-wrap {
  @media (min-width: 1126px) {
    display: flex;
  }
}

.header__submenu-wrap--show {
  @include viewport--md {
    display: flex;
  }
}

.header__nav-arrow--rotate {
  @include viewport--md {
    transform: rotate(90deg);
  }
}

.header__arrow-hidden {
  display: none;
}

// убираем стрелки в подменю
.header__submenu-item--no-arrow .header__submenu-link::before {
  @include viewport--md {
    display: none;
  }
}
