.about-clients {
  margin-bottom: 57px;

  @include viewport--sm {
    margin-bottom: 45px;
  }
}

.about-clients__list {
  @include reset-list;
  margin: 41px -16px 0 -16px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  @include viewport--md {
    margin: 31px -24px 0 -24px;
  }

  @include viewport--sm {
    margin: 21px -5px 0 -5px;
  }
}

.about-clients__item {
  margin: 0 16px 24px 16px;
  width: calc(25% - 16px * 2);

  @media (max-width: 1280px) {
    width: calc(33% - 16px * 2);
  }

  @include viewport--md {
    margin: 0 24px 0 24px;
    width: calc(50% - 24px * 2);
  }

  @include viewport--sm {
    margin: 0 5px 0 5px;
    width: calc(50% - 5px * 2);
  }
}
