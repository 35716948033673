.footer {
  padding: 0;
  font-family: $font-family--jost;
  font-size: 16px;
  line-height: 23px;
  color: $color-default-white;
  background-color: $color-night-rider;
}

.footer__section,
.footer__container {
  display: flex;
  justify-content: space-between;

  &:nth-child(2n) {
    background-color: $color-night-rider-2;
  }

  @include viewport--sm {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.footer__about {
  padding-top: 43px;
  padding-bottom: 45px;
  align-items: center;

  @include viewport--md {
    padding-top: 32px;
    padding-bottom: 14px;
    align-items: flex-start;
  }

  @include viewport--sm {
    padding-top: 42px;
    padding-bottom: 19px;
  }
}

.footer--news .footer__about {
  padding-top: 53px;
}

.footer__contacts {
  padding-top: 39px;
  padding-bottom: 35px;

  @include viewport--md {
    padding-bottom: 34px;
  }

  @include viewport--sm {
    padding-top: 24px;
    padding-bottom: 22px;
  }
}

.footer__bottom {
  padding-top: 39px;
  padding-bottom: 45px;
  opacity: 0.4;

  @include viewport--md {
    padding-top: 31px;
    padding-bottom: 39px;
    font-size: 14px;
  }

  @include viewport--sm {
    padding-top: 23px;
    padding-bottom: 46px;
  }
}

.footer__nav,
.footer__contacts-list,
.footer__social-list {
  @include reset-list;
  display: flex;
  flex-wrap: wrap;

  @include viewport--sm {
    width: 100%;
  }
}

.footer__logo {
  @include viewport--md {
    margin-top: -10px;
  }
}

.footer__nav {
  @include viewport--md-only {
    max-width: 70%;
    display: grid;
    grid-template-columns: minmax(100px, max-content) minmax(135px, max-content) minmax(153px, max-content);
  }

  @include viewport--sm {
    max-width: 100%;
    margin-top: 33px;
    justify-content: center;
  }
}

.footer__nav-item {
  font-weight: 600;
  margin-left: 32px;

  @include viewport--md {
    margin-bottom: 20px;
    margin-left: 0;
    padding-right: 10px;
  }

  @include viewport--sm {
    margin-bottom: 21px;
    padding-right: 0;
    width: 100%;
    text-align: center;
    font-size: 14px;
  }
}

.footer__contacts-list {
  margin-right: auto;

  @include viewport--sm {
    flex-basis: auto;
    flex-direction: column;
    align-items: center;
    width: auto;
    margin: 0 auto 12px;
    padding: 0 14%;
  }
}

.footer__contacts-item {
  font-weight: 500;
  margin-right: 32px;
  letter-spacing: 0;

  @include viewport--sm {
    min-width: 176px;
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: 19px;
  }
}

.footer__contacts-link {
  display: flex;
}

.footer__contacts-link span {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  width: 26px;
  height: 26px;
  background-color: white;
  border-radius: 50%;
}

.footer__contacts-link:hover span {
  background-color: $color-pigment-green;
}

.footer__contacts-link:active span {
  background-color: $color-silver;
}

.footer__social-list {
  margin-right: -12px;

  @include viewport--sm {
    padding: 0 14%;
    margin-right: 0;
    justify-content: center;
  }
}

.footer__social-item {
  margin-left: 12px;
  margin-right: 12px;
}

.footer__social-link svg {
  fill: $color-default-white;
  -webkit-transition: fill 0.4s ease-out;
  -moz-transition: fill 0.4s ease-out;
  -o-transition: fill 0.4s ease-out;
  transition: fill 0.4s ease-out;

  &:hover {
    fill: $color-pigment-green;
    -webkit-transition: fill 0.4s ease-out;
    -moz-transition: fill 0.4s ease-out;
    -o-transition: fill 0.4s ease-out;
    transition: fill 0.4s ease-out;
  }

  &:active {
    fill: $color-silver;
  }
}

.footer__copyright {
  @include viewport--sm {
    margin-bottom: 22px;
  }
}

.footer__copyright span {
  margin-right: 22px;

  @include viewport--sm {
    display: inline-block;
    width: 100%;
    margin-right: 0;
    text-align: center;
  }

  &:not(:last-child) {
    @include viewport--sm {
      margin-bottom: 7px;
    }
  }
}

.footer__additional-link {
  margin-left: 24px;
  text-decoration: underline;

  @include viewport--sm {
    display: inline-block;
    width: 100%;
    margin-left: 0;
    text-align: center;
  }

  &:not(:last-child) {
    @include viewport--sm {
      margin-bottom: 11px;
    }
  }
}

.footer a {
  color: inherit;
  -webkit-transition: color 0.4s ease-out;
  -moz-transition: color 0.4s ease-out;
  -o-transition: color 0.4s ease-out;
  transition: color 0.4s ease-out;

  &[href]:hover {
    color: $color-pigment-green;
    -webkit-transition: color 0.4s ease-out;
    -moz-transition: color 0.4s ease-out;
    -o-transition: color 0.4s ease-out;
    transition: color 0.4s ease-out;
  }

  &[href]:active {
    color: $color-silver;
  }
}

.footer__logo a[href] {
  display: block;

  &:focus {
    outline: none;
    box-shadow: 0 0 1px 1px $color-silver;
  }

  &:hover {
    box-shadow: 0 0 1px 1px $color-default-white;
  }

  &:active {
    box-shadow: 0 0 1px 1px $color-silver;
  }
}
