.navigation {
  padding: 32px 32px 31px 32px;

  @include viewport--md {
    padding: 13px 32px 0 32px;
  }

  @include viewport--sm {
    padding: 10px 20px 0 20px;
  }
}

.navigation__wrap {
  @include reset-list;
  width: fit-content;


  @include viewport--md {
    width: auto;
    margin: 0;
    display: flex;
    overflow: hidden;
    overflow-x: auto;

    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  @include viewport--sm {
    margin: 0;
  }
}

.service__nav br {
  display: none;

  @include viewport--sm {
    display: block;
  }
}

.navigation__item {
  margin: 0 0 24px 0;

  @include viewport--md {
    min-width: fit-content;
    margin: 0 33px 30px 0;
  }

  @include viewport--sm {
    // min-width: 128px;
    margin: 0 18px 0 0;
  }
}

.navigation__item:last-child {
  margin-bottom: 0;
}

.navigation__item a {
  display: block;
  font-size: 18px;
  line-height: 26px;
  font-weight: 500;
  text-align: left;
  color: $color-night-rider;
  opacity: 0.4;
  cursor: pointer;
  transition: 0.4s;

  &:hover {
    color: $color-pigment-green;
    opacity: 1;
  }

  &:active {
    color: $color-silver;
    outline: none;
    opacity: 1;
  }

  &:focus-within {
    outline: none;
    color: $color-pigment-green;
    opacity: 1;
  }

  @include viewport--sm {
    padding-bottom: 21px;
    font-size: 15px;
    line-height: 21px;
  }
}

.navigation__item--active a {
  cursor: default;
  position: relative;
  opacity: 1;
  transition: 0.4s;

  &::before {
    position: absolute;
    content: "";
    left: -32px;
    width: 4px;
    height: 26px;
    background-color: $color-pigment-green;
  }

  &:hover,
  &:active {
    color: $color-night-rider;
  }

  @include viewport--md {
    &::before {
      left: 0;
      bottom: -29px;
      width: 100%;
      height: 4px;
    }
  }

  @include viewport--sm {
    &::before {
      width: 95%;
      bottom: 0;
    }
  }
}
