.portfolio {
  position: relative;
  z-index: 4;
  padding-top: 82px;
  background: $grey-to-bottom;
  overflow: hidden;

  &::before {
    position: absolute;
    content: "";
    background-image: url("../img/svg/ellipse-2.svg");
    background-repeat: no-repeat, no-repeat;
    background-size: contain;
    width: 100%;
    max-width: 700px;
    height: 123%;
    bottom: -83%;
    left: 50%;
    transform: translateX(-49%);
    z-index: 0;
  }

  @include viewport--md {
    min-height: 420px;
    padding-top: 52px;

    &::before {
      max-width: 500px;
      height: 128%;
      transform: translateX(-50%);
    }
  }

  @include viewport--sm {
    min-height: 357px;
    padding-top: 32px;

    &::before {
      max-width: 226px;
      bottom: -107%;
    }
  }
}

.portfolio__wrapper {
  position: relative;
  padding-bottom: 0;
  max-width: 1440px;
  padding-left: 150px;
  padding-right: 150px;

  @media (max-width: 1023px) {
    padding-left: 30px;
    padding-right: 30px;
  }

  @include viewport--sm {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 39px;
  }
}

.portfolio__title {
  text-align: center;
  margin-bottom: 20px;

  @include viewport--md {
    margin-bottom: 16px;
  }

  @include viewport--sm {
    margin-bottom: 12px;

    font-size: 20px;
    line-height: 29px;
  }
}

.portfolio__description {
  max-width: 85%;
  margin: 0 auto;
  text-align: center;
  font-size: 18px;
  line-height: 26px;

  @include viewport--md {
    margin-top: 15px;
    margin-bottom: 32px;
    font-size: 16px;
    line-height: 23px;
  }

  @include viewport--sm {
    font-size: 14px;
    line-height: 20px;
    margin: 14px auto 0;
    max-width: unset;
  }
}

.portfolio__slider-wrap {
  padding-bottom: 111px;

  @include viewport--sm {
    padding-bottom: 72px;
  }
}

.portfolio__slide {
  height: auto;
}

.portfolio__slider {
  margin-top: 59px;
  margin-bottom: 0;

  @include viewport--md {
    overflow: unset;
    margin-top: 20px;
  }

  @include viewport--sm {
    margin-top: 22px;
  }
}

.portfolio__prev-slide,
.portfolio__next-slide {
  left: 50px;
  bottom: 192px;
  top: unset;

  @include viewport--md {
    display: none;
  }
}

.portfolio__next-slide {
  left: unset;
  right: 50px;
  bottom: 192px;
  top: unset;
}

.portfolio__slide .partner-card {
  width: unset;
  height: 100%;
  margin: 0;
}

.portfolio__pagination {
  position: static;
  display: none;

  @include viewport--md {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -87px;
    z-index: 20;
  }

  @include viewport--sm {
    margin-top: -48px;
  }
}

.portfolio__pagination.swiper-pagination-clickable {
  & span {
    background: $color-whisper;
    border: 1px solid $color-matterhorn;
    box-sizing: border-box;
    margin-right: 9px;
    outline: none;

    &:last-of-type {
      margin-right: 0;
    }
  }

  & .swiper-pagination-bullet-active {
    width: 10px;
    height: 10px;
    background-color: $color-pigment-green;
    border: none;
  }
}
