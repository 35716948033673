.requisites {
  max-width: 670px;
  margin: 0 0 81px 0;
  padding: 0;

  @include viewport--md {
    max-width: 100%;
    margin: 0 0 84px 0;
  }

  @include viewport--sm {
    margin: 0 0 41px 0;
  }
}

.requisites h2 {
  margin: 0 0 27px 0;

  @include viewport--sm {
    margin: 0 0 18px 0;
  }
}

.requisites p {
  margin: 0 0 12px 0;

  @include viewport--sm {
    margin: 0 0 8px 0;
  }
}
