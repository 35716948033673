.buttons-group {
  &--four {
    @media (max-width: 800px) {
      width: 100vw;
      margin-left: -30px;
      padding: 0;
      overflow-x: scroll;
    }
  }

  @include viewport--md {
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }

  @include viewport--sm {
    width: 100vw;
    margin-left: -20px;
    padding: 0;
    overflow-x: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
}

.buttons-group__list {
  @include reset-list;
  margin: 0 0 20px 0;
  display: flex;
  width: 100%;

  @include viewport--sm {
    margin-left: 20px;
    margin-bottom: 0;
  }
}

.buttons-group--four .buttons-group__list {
  @media (max-width: 800px) and (min-width: 768px) {
    margin-left: 30px;
    margin-bottom: 0;
  }
}

.buttons-group__item {
  margin: 0 16px 0 0;

  @include viewport--sm {
    margin: 0 10px 0 0;
  }
}

.buttons-group__item:last-of-type {
  padding-right: 50px;

  @include viewport--sm {
    padding-right: 20px;
  }
}

.buttons-group__btn {
  min-width: 180px;
  max-width: 300px;
  min-height: 42px;
  padding: 8px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-default-white;
  font-size: 17px;
  line-height: 24px;
  font-weight: 500;
  background-color: $color-pigment-green;
  border-radius: 31px;
  border: 1px solid transparent;
  outline: none;
  cursor: pointer;

  @include viewport--sm {
    min-width: 120px;
    min-height: 32px;
    font-size: 13px;
    line-height: 18px;
  }

  &--active {
    cursor: auto;
  }
}

.buttons-group__btn:not(.buttons-group__btn--active) {
  color: $color-night-rider;
  background-color: $color-gainsboro;
  -webkit-transition: color 0.4s ease-out;
  -moz-transition: color 0.4s ease-out;
  -o-transition: color 0.4s ease-out;
  transition: color 0.4s ease-out;
  -webkit-transition: background-color 0.4s ease-out;
  -moz-transition: background-color 0.4s ease-out;
  -o-transition: background-color 0.4s ease-out;
  transition: background-color 0.4s ease-out;

  &:hover {
    color: $color-pigment-green;
    background-color: $color-default-white;
    border: 1px solid $color-pigment-green;
    -webkit-transition: color 0.4s ease-out;
    -moz-transition: color 0.4s ease-out;
    -o-transition: color 0.4s ease-out;
    transition: color 0.4s ease-out;
    -webkit-transition: background-color 0.4s ease-out;
    -moz-transition: background-color 0.4s ease-out;
    -o-transition: background-color 0.4s ease-out;
    transition: background-color 0.4s ease-out;
  }

  &:disabled {
    opacity: 0.3;
  }
}
