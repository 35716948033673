.partners {
  margin-top: 34px;
  padding: 52px 0 83px;
  background-image: $grey-to-bottom;

  @include viewport--md {
    margin-top: 13px;
    padding-top: 33px;
    padding-bottom: 82px;
  }

  @include viewport--sm {
    margin-top: 1px;
    padding-top: 21px;
    padding-bottom: 62px;
  }
}

.partners__filter-buttons {
  position: relative;
  z-index: 3;
  margin-top: 41px;

  @include viewport--sm {
    margin-top: 17px;
  }
}

.partners__content {
  padding-top: 32px;

  @include viewport--sm {
    padding-top: 24px;
  }
}

.partners__filter-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 32px);
  margin-bottom: 19px;

  @include viewport--md {
    width: calc(100% + 24px);
    margin-bottom: 38px;
  }

  @include viewport--sm {
    width: calc(100% + 10px);
    margin-bottom: 20px;
  }
}

.partners__filter .show-more {
  margin: 0 auto;
}

.partners--no-js .buttons-group__btn:not(.buttons-group__btn--active) {
  opacity: 0.3;
  pointer-events: none;
  cursor: text;
}

.partners__filter {
  display: none;
}

.partners__filter--active {
  display: block;
}

.partners__mod {
  padding-bottom: 0;
}
