.no-page {
  background: $grey-to-bottom;
}

.no-page__container {
  position: relative;
  margin: 33px 0 0 0;
  padding: 95px 0 49px 0;
  z-index: 20;

  @include viewport--md {
    margin: 13px 0 0 0;
    padding: 82px 0 40px 0;
  }

  @include viewport--sm {
    margin: 0;
    padding: 97px 0 81px 0;
  }
}

.no-page h1 {
  margin: 0 0 0 -3px;
  font-size: 32px;
  line-height: 46px;
  font-weight: 600;
  text-align: center;

  @include viewport--md {
    margin: 0 0 0 6px;
  }

  @include viewport--sm {
    margin: 0;
    font-size: 18px;
    line-height: 26px;
  }
}

.no-page__wrap {
  width: fit-content;
  margin: 0 auto;
}

.no-page__block {
  position: relative;
  margin: 0;
  padding: 0;
  font-size: 18vw;
  line-height: normal;
  font-weight: 700;
  letter-spacing: 15px;
  color: $color-pigment-green;

  &::before {
    position: absolute;
    content: "";
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 31%;
    height: 47%;
    border-radius: 50%;
    background-color: $color-pigment-green;
    background-image: url("../img/svg/search-desctop.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 50%;
  }

  @include viewport--md {
    margin: 0 0 0 11px;
    font-size: 33vw;

    &::before {
      width: 32%;
      height: 49%;
      transform: translate(-51%, -50%);
    }
  }

  @include viewport--sm {
    margin: -8px 0 0 -3px;
    padding: 0;
    font-size: 42vw;
    letter-spacing: 7px;

    &::before {
      background-image: url("../img/svg/search-mobile.svg");
    }
  }

  @media (min-width: $viewport--xl) {
    font-size: 256px;
  }
}
