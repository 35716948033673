@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: "jost";

  font-display: swap;
  src:
    url("../fonts/Jost-Regular.woff2") format("woff2"),
    url("../fonts/Jost-Regular.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 500;
  font-family: "jost";

  font-display: swap;
  src:
    url("../fonts/Jost-Medium.woff2") format("woff2"),
    url("../fonts/Jost-Medium.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-family: "jost";

  font-display: swap;
  src:
    url("../fonts/Jost-SemiBold.woff2") format("woff2"),
    url("../fonts/Jost-SemiBold.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-family: "jost";

  font-display: swap;
  src:
    url("../fonts/Jost-Bold.woff2") format("woff2"),
    url("../fonts/Jost-Bold.woff") format("woff");
}

@font-face {
  font-style: italic;
  font-weight: 400;
  font-family: "jost";

  font-display: swap;
  src:
    url("../fonts/Jost-Italic.woff2") format("woff2"),
    url("../fonts/Jost-Italic.woff") format("woff");
}
