.quote {
  padding: 23px 28px;

  background-color: $color-white-smoke-2;
  background-image:
    url("../../img/svg/quote.svg"),
    url("../../img/svg/quote.svg");
  background-size: 1.6%;
  background-position: 28px 25px, 53px 25px;
  background-repeat: no-repeat, no-repeat;

  & p {
    font-style: italic;
    font-size: 16px;
    line-height: 23px;
    color: $color-night-rider;
  }
}

.quote__title {
  display: flex;
  justify-content: flex-end;

  & h6 {
    margin: 0;
    text-align: right;
    margin-right: 20px;

    & span {
      display: block;

      font-size: 12px;
      line-height: 17px;
      font-weight: normal;
      text-align: right;
    }
  }
}
