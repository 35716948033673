.message-send {
  margin: 0 auto;
  padding: 80px 60px;
  max-width: 350px;
  width: 100%;
  min-height: 362px;
  text-align: center;
  background-color: $color-white-smoke-2;
  border-radius: 2px;

  &--contacts-ok {
    padding-top: 102px;
  }

  &--contacts-error {
    padding-top: 83px;
    padding-bottom: 75px;
  }

  &--event {
    max-width: 100%;
    margin: 73px 0 0 0;
    min-height: 376px;
    padding: 192px 81px 73px 80px;
  }

  @include viewport--sm {
    &--event {
      min-height: 240px;
      padding: 140px 30px 50px 30px;
    }
  }
}

.message-send svg {
  margin-bottom: 13px;
}

.message-send strong {
  display: inline-block;
  font-weight: 600;
}

.message-send p {
  font-size: 16px;
  line-height: 23px;
}
