.partner-card {
  display: none;
  margin-right: 32px;
  margin-bottom: 32px;
  width: calc(25% - 32px);
  background-color: $color-default-white;
  border-radius: 6px;
  box-shadow: 0 120px 120px 0 rgba(0, 0, 0, 0.02);

  &--filtered {
    display: block;
  }

  @include viewport--md {
    width: calc(33.3% - 24px);
    margin-right: 24px;
    margin-bottom: 24px;
  }

  @include viewport--sm {
    width: calc(50% - 10px);
    margin-right: 10px;
    margin-bottom: 12px;
  }
}

.partner-card--filtered.partner-card--hidden {
  display: none;
}

.partner-card__logo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 20px 20px 20px;
  height: 120px;
  width: 100%;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.03);

  @include viewport--md {
    padding: 29px 20px 29px 20px;
    height: 100px;
  }

  @include viewport--sm {
    height: 80px;
    padding: 24px 10px 24px 10px;
  }
}

.partner-card__logo img {
  object-fit: contain;
  max-height: 80px;

  @include viewport--md {
    max-height: 60px;
  }

  @include viewport--sm {
    max-height: 50px;
    max-width: 90%;
    margin: 0 auto;
  }
}

.partner-card__links {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 30px 15px 37px;

  @include viewport--md {
    padding: 18px 18%;
    min-height: 90px;
    align-items: center;
  }

  @include viewport--sm {
    padding: 10px 25%;
    min-height: 80px;
  }

  @media (max-width: 450px) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.partner-card__links a {
  margin: 0 8px;
  font-size: 16px;
  line-height: 23px;
  text-decoration: underline;

  &:hover,
  &:active {
    text-decoration: none;
  }

  @include viewport--md {
    font-size: 14px;
    line-height: 20px;
  }

  @include viewport--sm {
    font-size: 12px;
    line-height: 17px;
  }
}

.partner-card__links .partner-card__link--green {
  font-weight: 600;

  @include viewport--md {
    margin-bottom: 14px;
  }
}

.partner-card__link--gray {
  color: $color-nobel;
}
