.link {
  color: $color-night-rider;
  -webkit-transition: color 0.4s ease-out;
  -moz-transition: color 0.4s ease-out;
  -o-transition: color 0.4s ease-out;
  transition: color 0.4s ease-out;

  &:hover,
  &:focus {
    color: $color-pigment-green-1;
    -webkit-transition: color 0.4s ease-out;
    -moz-transition: color 0.4s ease-out;
    -o-transition: color 0.4s ease-out;
    transition: color 0.4s ease-out;
  }

  &:active {
    color: $color-silver;
  }

  &--green {
    color: $color-pigment-green;

    &:hover,
    &:focus {
      color: $color-salem;
    }

    &:active {
      color: $color-nobel;
    }
  }
}
