.title {
  margin: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 46px;

  @include viewport--md {
    font-size: 24px;
    line-height: 35px;
  }

  @include viewport--sm {
    font-size: 20px;
    line-height: 29px;
  }

  &--h2 {
    font-size: 28px;
    line-height: 40px;

    @include viewport--md {
      font-size: 24px;
      line-height: 35px;
    }
  }

  &--h3 {
    font-size: 24px;
    line-height: 35px;

    @include viewport--sm {
      font-size: 18px;
      line-height: 26px;
    }
  }

  &--h4 {
    font-size: 20px;
    line-height: 29px;

    @include viewport--md {
      font-size: 16px;
      line-height: 23px;
    }
  }

  &--h5 {
    font-size: 18px;
    line-height: 26px;

    @include viewport--sm {
      font-size: 16px;
      line-height: 23px;
    }
  }

  &--h6 {
    font-size: 16px;
    line-height: 23px;
  }
}

h1 {
  margin: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 46px;

  @include viewport--sm {
    font-size: 20px;
    line-height: 29px;
  }
}

h2 {
  margin: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 40px;
}

h3 {
  margin: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 35px;
}

h4 {
  margin: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 29px;
}

h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
}

h6 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 23px;
}
