.clients-card {
  overflow: hidden;
  position: relative;
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 180px;
  background-color: #ffffff;
  box-shadow: 0 120px 120px rgba(0, 0, 0, 0.02);

  @include viewport--md {
    height: 125px;
    margin: 0 0 25px 0;
  }

  @include viewport--sm {
    margin: 0 0 12px 0;
    padding: 10px;
  }
}

.clients-card__block {
  position: absolute;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 16px;
  background-color: $color-pigment-green;
  color: #ffffff;
  box-shadow: 0 120px 120px rgba(0, 0, 0, 0.07);
  display: none;

  @include viewport--md {
    padding: 15px;
  }

  @include viewport--sm {
    padding: 13px 10px 10px 10px;
    align-items: flex-start;
  }
}

.clients-card img {
  object-fit: contain;

  @include viewport--md {
    max-height: 155px;
  }

  @include viewport--sm {
    max-height: 125px;
  }
}

.clients-card > .clients-card__block p {
  text-overflow: ellipsis;
  margin: 0 auto 25px auto;
  text-align: center;
  font-size: 13px;
  line-height: 17px;
  font-weight: 400;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
  overflow: hidden;


  @include viewport--md {
    margin: 0 auto 21px auto;
    -webkit-line-clamp: 3;
  }

  @include viewport--sm {
    align-items: center;
    min-height: 44px;
    margin: 0 0 23px 0;
    font-size: 8px;
    line-height: 12px;
    -webkit-line-clamp: 4;

    &::before {
      bottom: -11px;
    }
  }
}

.clients-card__block-link {
  position: relative;
  display: flex;
  justify-content: center;
  margin: 0 -8px 0 -8px;

  &::before {
    position: absolute;
    content: "";
    top: -8px;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 2px;
    background-color: rgba(255, 255, 255, 0.4);
  }

  @include viewport--sm {
    flex-direction: column;
    margin: 0 auto;
    row-gap: 7px;
  }
}

.clients-card__block a {
  display: block;
  margin: 0 8px 0 8px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: #ffffff;
  text-align: center;
  -webkit-transition: opacity 0.4s ease-out;
    -moz-transition: opacity 0.4s ease-out;
    -o-transition: opacity 0.4s ease-out;
    transition: opacity 0.4s ease-out;

  &:hover {
    opacity: 0.6;
    -webkit-transition: opacity 0.4s ease-out;
    -moz-transition: opacity 0.4s ease-out;
    -o-transition: opacity 0.4s ease-out;
    transition: opacity 0.4s ease-out;
  }

  @include viewport--sm {
    font-size: 8px;
    line-height: 11px;
    margin: 0;
  }
}

.clients-card:hover .clients-card__block {
  display: flex;
}
