.our-services {
  box-shadow: 0 100px 100px rgba(0, 0, 0, 0.05);
  border-radius: 30px;
  z-index: 3;
  background-color: $color-default-white;
}

.our-services__image {
  height: 290px;

  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  background-color: $color-white-smoke;

  @include viewport--md {
    height: 196px;

    & img {
      width: auto;
      height: 211px;
      object-fit: contain;
    }
  }

  @include viewport--sm {
    height: 180px;
  }
}

.our-services__image img {
  margin: 10px 0 0 ;
}

.our-services__desc-wrap {
  padding: 15px 70px 31px 31px;
  background-color: $color-default-white;

  @include viewport--md {
    padding: 24px 58px 24px 25px;
  }

  @include viewport--sm {
    padding: 18px 22px 24px 20px;
  }
}

.our-services__title {
  margin-bottom: 10px;
}

.our-services__description {
  margin-top: 0;
  margin-bottom: 10px;

  font-size: 18px;
  line-height: 26px;

  @include viewport--md {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 14px;
  }

  @include viewport--sm {
    font-size: 12px;
    line-height: 17px;
    margin-bottom: 9px;
  }
}

.our-services__more-link {
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;

  @include viewport--md {
    font-size: 14px;
    line-height: 20px;
  }
}

.our-services__pagination {
  @include viewport--sm {
    display: none;
  }

  & > .swiper-pagination-bullets {
    bottom: 150px;
    left: 645px;

    display: flex;
    flex-direction: column;

    @include viewport--lg {
      left: 610px;
    }

    @include viewport--md {
      bottom: 104px;
      left: 373px;
    }

    @include viewport--sm {
      flex-direction: row;
      justify-content: center;

      bottom: 15px;
      left: 0;
    }

    & span {
      margin: 0;
      margin-bottom: 16px;
      width: 12px;
      height: 12px;

      background: $color-whisper;
      border: 1px solid $color-matterhorn;
      box-sizing: border-box;

      @include viewport--md {
        width: 8px;
        height: 8px;
        margin-bottom: 10px;
      }

      @include viewport--sm {
        margin-bottom: 0;
        margin-right: 10px;
      }
    }

    & .swiper-pagination-bullet-active {
      width: 16px;
      height: 16px;
      background-color: $color-pigment-green;
      border: none;
      margin-left: -1px;

      @include viewport--md {
        width: 10px;
        height: 10px;
      }
    }
  }
}

.our-services__pagination--mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 165px;
  right: 90px;
  z-index: 10;

  @include viewport--md {
    right: 51px;
  }

  @include viewport--sm {
    display: block;
    width: 100%;
    left: 0;
    top: unset;
    bottom: 26px;
    margin-top: 12px;
  }

  & > .swiper-pagination-bullet {
    margin: 0;
    margin-bottom: 14px;
    width: 12px;
    height: 12px;
    outline: none;
    background: $color-whisper;
    border: 1px solid $color-matterhorn;
    box-sizing: border-box;

    @include viewport--md {
      width: 7.5px;
      height: 7.5px;
    }

    @include viewport--sm {
      margin-bottom: 0;
      margin-right: 10px;
    }

    &:last-of-type {
      @include viewport--md {
        margin-right: 0;
      }
    }
  }

  & > .swiper-pagination-bullet-active {
    width: 16px;
    height: 16px;
    vertical-align: middle;
    background-color: $color-pigment-green;
    border: none;

    @include viewport--md {
      width: 10px;
      height: 10px;
    }
  }
}
