.news-page {
  background: $grey-to-bottom;
}

.news-page__block {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 43px 0 65px;
  margin: 43px 0 0 0;
  z-index: 20;

  &--event {
    padding-top: 52px;
    padding-bottom: 66px;
    margin-top: 33px;
  }

  @include viewport--md {
    margin: 0;

    &--event {
      padding-top: 43px;
    }
  }

  &--mod {
    max-width: 847px;
    width: 100%;
    margin: 0 auto;
    padding-bottom: 92px;

    @include viewport--md {
      margin: 0 auto;
      padding-bottom: 40px;
    }

    @include viewport--sm {
      padding-bottom: 30px;
    }
  }
}

.news-page__left {
  width: 58%;
  margin: 8px 20px 0 0;

  &--mod {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-right: 0;
  }

  @include viewport--md {
    width: 100%;
  }
}

.news-page__left time {
  font-size: 12px;
  line-height: 17px;
  font-weight: 600;
  color: $color-silver;
}

.news-page__left--mod time {
  align-self: left;
  padding-left: 2px;
}

.news-page__left h1 {
  margin: 7px 0 32px 0;

  @include viewport--md {
    margin: 7px 0 25px 0;
  }

  @include viewport--sm {
    margin: 7px 0 15px 0;
  }
}

.news-page__left--mod h1 {
  margin-top: 10px;
  text-align: center;
}

.news-page__addition {
  background-color: #ffffff;
  box-shadow: 0 100px 100px $color-black-opacity;
  padding: 35px 32px 30px 35px;
  border-radius: 4px;
}

.news-page__addition h2 {
  margin: 0 0 26px 0;
}

.news-page__addition strong {
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
}

.news-page__card {
  margin: 0 0 18px 0;
  padding: 0 50px 15px 0;
  border-bottom: 1px solid $color-whisper-3;

  @include viewport--sm {
    padding: 0;
  }
}

.news-page__card:last-of-type {
  border-bottom: none;
  padding-bottom: 0;
}

.news-page__card:last-child {
  margin: 0;
}

.news-page__card strong {
  margin-bottom: 6px;

  @include viewport--sm {
    font-size: 16px;
    line-height: 23px;
  }
}

.news-page__right {
  min-width: 310px;
  width: 36%;
  margin: 8px 0 0 0;

  @include viewport--md {
    width: 407px;
    margin: 0 auto;

    &--addition {
      width: 100%;
    }
  }

  @include viewport--sm {
    min-width: 280px;
    max-width: 280px;

    &--addition {
      max-width: 100%;
    }
  }
}

.news-page__event {
  @include viewport--md {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  @include viewport--sm {
    margin-top: 0;
  }
}
